import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, SecondaryCallout, KMCurve, BoxedDefinitionList, ReferencesBlock, NCCNcallout, PageSubSection } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import ARCC from '../../components/StudyDesign/__study-design/arcc'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-564 - Clinical Trial Results & Study Design | HCP`,
    keywords: `keynote 564, keynote-564`,
    description: `Health care professionals may find clinical trial results from KEYNOTE-564 for the adjuvant treatment of certain patients with renal cell carcinoma (RCC).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/adjuvant-renal-cell-carcinoma","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠564","description":"Clinical Findings from KEYNOTE⁠-⁠564","trialDesign":"Phase 3, multicenter, randomized, double-blind, placebo-controlled trial in 994 patients.1"}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/adjuvant-renal-cell-carcinoma","@type":"MedicalAudience","audienceType":"health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 29;
// const jobCode = jobCodes[8].jobCode;
const jobCode = "US-JRC-00857 09/24";

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'Advanced RCC', url: '/efficacy/advanced-renal-cell-carcinoma/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/adjuvant-renal-cell-carcinoma/' },
                { text: 'Dosing', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const KMofDFSFootnotes = [
    {
        label: "a.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "b.",
        text: "Based on stratified log-rank test."
    },
    {
        label: "c.",
        text: "<i>P</i> value (one-sided) is compared with a boundary of 0.0114."
    },
]

const KMofDFSFootnotesNew = [
    {
        label: "d.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "e.",
        text: "Based on stratified log-rank test."
    },
    {
        label: "f.",
        text: "<i>P</i> value (one-sided) is compared with a boundary of 0.0072."
    },
]

const boxedFootnoteList = [
    {
        label: "i.",
        text: "Patients with metastatic disease who had undergone complete resection of primary and metastatic lesions."
    },
]

const boxedDefinitions = `T2 = tumor >7 cm in greatest dimension, limited to the kidney; M0 = no distant metastasis; T4 = tumor invades beyond Gerota’s fascia (including contiguous extension into the ipsilateral adrenal gland); N1 = metastasis in regional lymph node(s).`;

const patientInclusionCriteriaItems = [
    {
        header: "Intermediate-high risk of recurrence",
        description: `<p>pT2 with Grade 4 or sarcomatoid, N0, M0<sup>1</sup></p>
                      <p>pT3, any grade, N0, M0</p>
        `
    },
    {
        header: "High risk of recurrence",
        description: `<p>pT4, any grade, N0, M0</p>
                      <p>Any pT, any grade, N1, M0</p>
        `
    },
    {
        header: "M1 NED",
        description: `<p>M1 no evidence of disease<sup>i</sup></p>`
    },
]

const NCCNcalloutFootnotes = [
    {
        label: "j.",
        text: " Surveillance and clinical trials are also Category 2A recommended options for certain patients with ccRCC.<sup>4</sup>"
    },
]

const NCCNcalloutdefinitions = `
                                <p>Category 1 = Based upon high-level evidence (≥1 randomized phase 3 trials or high-quality, robust meta-analyses), there is uniform NCCN consensus (≥85% support of the Panel) that the intervention is appropriate.<sup>4</sup></p>
                                <p>Category 2A = Based upon lower-level evidence, there is uniform NCCN consensus (≥85% support of the Panel) that the intervention is appropriate.<sup>4</sup></p>
                                <p>NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.</p>
                                <p>ccRCC = clear cell renal cell carcinoma.</p>
                                `;

const NCCNcalloutData = {
    title: '<b>Pembrolizumab (KEYTRUDA®)</b> is the <b>ONLY NCCN RECOMMENDED adjuvant treatment</b> for certain patients with ccRCC post nephrectomy<sup>4,j</sup>',
    showBadge: false,
    bodyCopy: `
      
        <ul>
            <li>NCCN CATEGORY 1—Stage II ccRCC with Grade 4 tumors +/- sarcomatoid features</li>
            <li>NCCN CATEGORY 1—Stage III ccRCC</li>
            <li>NCCN CATEGORY 2A—Stage IV ccRCC after metastasectomy with complete resection of disease within 1 year of nephrectomy</li>
            <li>NCCN CATEGORY 2A—Stage IV ccRCC with resectable T4 tumors without distant metastasis (T4, M0)</li>
        </ul>
    `,
}

const NCCNcalloutReferences = [
    {
        label: "4.",
        text: `Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Kidney Cancer V.2.2025. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed September 9, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org.`
    },
];


const pageReferences = [
    {
        label: "1.",
        text: "Choueiri TK, Tomczak P, Park SH, et al; for the KEYNOTE&#8288;-&#8288;564 investigators. Adjuvant pembrolizumab after nephrectomy in renal-cell carcinoma. <i>N Engl J Med.</i> 2021;385(8):683–694."
    },
    {
        label: "2.",
        text: `Choueiri TK, Tomczak P, Park SH, et al. Overall survival with adjuvant pembrolizumab in renal-cell carcinoma. <i>N Engl J Med.</i> 2024;390:1359-1371.`
    },
    {
        label: "3.",
        text: `Powles T, Tomczak P, Park SH, et al. Pembrolizumab versus placebo as post-nephrectomy adjuvant therapy for clear cell renal cell carcinoma (KEYNOTE&#8288;-&#8288;564): 30-month follow-up analysis of a multicentre, randomised, double-blind, placebo-controlled, phase 3 trial. <i>Lancet Oncol.</i> 2022;23(9):1133-1144. doi: 10.1016/S1470-2045(22)00487-9.`
    },
    {
        label: "4.",
        text: `Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Kidney Cancer V.2.2025. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed September 9, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org.`
    },
]




const anchorLinkModelData = [
    { label: 'KEYNOTE&#8288;-&#8288;564: DFS at First Interim Analysis<sup>1</sup> <br> <span as="div" style="display: block;margin-top: 10px;font-weight: 400;font-size: 16px;line-height: 24px;color: #444444;">(Median Follow-Up: 24.1 Months)</span>' },
    { label: 'KEYNOTE&#8288;-&#8288;564: DFS at Third Interim Analysis<sup>2</sup> <br> <span as="div" style="display: block;margin-top: 10px;font-weight: 400;font-size: 16px;line-height: 24px;color: #444444;">(Median Follow-Up: 57.2 Months)</span>' },
    { label: 'KEYNOTE&#8288;-&#8288;564: OS at Third Interim Analysis<sup>2</sup> <br> <span as="div" style="display: block;margin-top: 10px;font-weight: 400;font-size: 16px;line-height: 24px;color: #444444;">(Median Follow-Up: 57.2 Months)<sup>2</sup></span>' },
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;564' },
    { label: 'Patient Inclusion Criteria' },
    { label: 'Select NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Kidney Cancer<sup>4</sup>' }
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">KEYTRUDA is the ONLY APPROVED SYSTEMIC TREATMENT to demonstrate BOTH DFS AND OS BENEFIT vs placebo in the adjuvant setting for patients with RCC at increased risk of recurrence post nephrectomy<sup>1,2</sup></PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">

                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>

                            <SecondaryCallout 
                                title="Superior DFS with KEYTRUDA vs placebo" 
                                text="HR<sup>a</sup>=0.68 (95% CI, 0.53–0.87); <i>P</i><sup>b</sup>=0.0010<sup>c</sup>" 
                                alt=""    
                            />

                            <KMCurve
                                title="Kaplan-Meier Estimates of DFS in KEYNOTE&#8288;-&#8288;564<sup>1</sup>"
                                limitation="Median time from randomization to data cutoff: 24.1 months (range, 14.9–41.5)"
                                hr="32% reduction in the risk of disease recurrence, metastasis or death with KEYTRUDA vs placebo; HR<sup>a</sup>=0.68 (95% CI, 0.53–0.87); <i>P</i><sup>b</sup>=0.0010<sup>c</sup>"
                                footnotes={KMofDFSFootnotes}
                                definitions="DFS = disease-free survival; OS = overall survival; NCCN® = National Comprehensive Cancer Network®; HR = hazard ratio; CI = confidence interval."
                                additionalInformation={[
                                    {
                                        title: 'Additional Information',
                                        text: `
                                            <p>Events observed: 109/496 (22%) with KEYTRUDA vs 151/498 (30%) with placebo.</p>
                                            <p>Median DFS: Not reached for KEYTRUDA or placebo.</p>`
                                           
                                    },
                                ]}  
                                image={() => <StaticImage 
                                    src="../../assets/arcc-564-km-v5.png" 
                                    placeholder="blurred" 
                                    alt="KEYNOTE-564 Kaplan-Meier Estimates of DFS for the First Interim Analysis in the Adjuvant Treatment of Patients With Renal Cell Carcinoma at Increased Risk of Recurrence" />} 
                            />
                            <SecondaryCallout 
                                title="KEYTRUDA is the ONLY IMMUNOTHERAPY APPROVED for the adjuvant treatment of patients with RCC at higher risk of recurrence post nephrectomy<sup>1</sup>" 
                                alt=""    
                                // footnotes={secondaryCalloutFootnote}
                            />


                        </PageSection>

                        <PageSection title={anchorLinkModelData[1].label}>

                            <SecondaryCallout 
                                title="LIMITATIONS: Statistical testing for DFS was completed at First Interim Analysis, and, therefore, no formal statistical testing was performed at Third Interim Analysis. No conclusions can be drawn." 
                                alt=""    
                                text="HR=0.72 (95% CI, 0.59–0.87)"
                            />

                            <KMCurve
                                title="Kaplan-Meier Estimates of DFS in KEYNOTE&#8288;-&#8288;564<sup>2</sup>"
                                limitation="Median time from randomization to data cutoff: 57.2 months (range: 47.9–74.5 months)"
                                hr="HR=0.72 (95% CI, 0.59–0.87)"
                                copyrightAttribution="From <i>N Engl J Med</i>, Choueiri TK, Tomczak P, Park SH, et al., Overall survival with adjuvant pembrolizumab in renal-cell carcinoma, 390., 1359–1371. Copyright ©2024 Massachusetts Medical Society. Reprinted with permission from Massachusetts Medical Society."
                                
                                additionalInformation={[
                                    {
                                        title: 'Additional Information',
                                        text: `<p>Events observed: 174/496 (35%) with KEYTRUDA vs 224/498 (45%) with placebo<sup>2</sup></p>
                                            <p>Median DFS: Not reached for KEYTRUDA or placebo<sup>2</sup></p>`
                                        
                                    },
                                ]}  
                                image={() => <StaticImage 
                                    src="../../assets/arcc-564-km-followup-v5.png" 
                                    placeholder="blurred" 
                                    alt="KEYNOTE-564 Kaplan-Meier Estimates of Follow-up DFS for the Third Interim Analysis in the Adjuvant Treatment of Patients With Renal Cell Carcinoma at Increased Risk of Recurrence" />} 
                            />
                       
                        </PageSection>


                        {/* ITEM 39 */}
                        <PageSection bgColor="cloud" title={anchorLinkModelData[2].label}>
                            
                            {/* <PageSubSection> */}
                            <SecondaryCallout 
                                title="Superior OS with KEYTRUDA vs placebo" 
                                alt=""    
                                text="HR<sup>d</sup>=0.62 (95% CI, 0.44–0.87); <i>P</i><sup>e</sup>=0.0024<sup>f</sup>"
                            />
                            <KMCurve
                                title="Kaplan-Meier Estimates of OS in KEYNOTE&#8288;-&#8288;564<sup>2</sup>"
                                limitation="Median time from randomization to data cutoff: 57.2 months (range: 47.9–74.5 months)"
                                hr="38% reduction in the risk of death with KEYTRUDA vs placebo; HR<sup>d</sup>=0.62 (95% CI, 0.44–0.87); <i>P</i><sup>e</sup>=0.0024<sup>f</sup>"
                                footnotes={KMofDFSFootnotesNew}
                                additionalInformation={[
                                    {
                                        title: 'Additional Information',
                                        text: `<p>Events observed: 55/496 (11%) with KEYTRUDA vs 86/498 (17%) with placebo.<sup>2</sup></p>
                                            <p>Median OS: Not reached for KEYTRUDA or placebo.<sup>2</sup></p>`
                                    },
                                ]} 
                               
                                image={() => <StaticImage 
                                    src="../../assets/km-rcc-564-v2.png" 
                                    placeholder="blurred" 
                                    alt="KEYNOTE-564 Kaplan-Meier Estimates of Follow-up OS for the Third Interim Analysis in the Adjuvant Treatment of Patients With Renal Cell Carcinoma at Increased Risk of Recurrence" />} 
                            />
                              <SecondaryCallout 
                                title="KEYTRUDA is the ONLY APPROVED SYSTEMIC TREATMENT to demonstrate an OS BENEFIT vs placebo in the adjuvant setting for patients with RCC at increased risk of recurrence post nephrectomy<sup>2</sup>" 
                                alt=""    
                                // footnotes={secondaryCalloutFootnote}
                            />
                            
                            {/* </PageSubSection> */}
                        </PageSection>

                        <PageSection title={anchorLinkModelData[3].label}>
                            <ARCC />
                        </PageSection>
                        
                        <PageSection title={anchorLinkModelData[4].label}>
                            <BoxedDefinitionList 
                                items={patientInclusionCriteriaItems}
                                footnotes={boxedFootnoteList}
                                definitions={boxedDefinitions}
                            />
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[5].label}>
                            <NCCNcallout
                                data={NCCNcalloutData}
                                footnotes={NCCNcalloutFootnotes}
                                title='<h2 style="line-height:20px">Discuss pembrolizumab (KEYTRUDA) with ALL patients with Stage III ccRCC post nephrectomy— the ONLY CATEGORY 1 recommended adjuvant treatment for these patients
                                </h2>'
                                definitions={NCCNcalloutdefinitions}
                                references={NCCNcalloutReferences}
                            />

                            <SecondaryCallout 
                                title='All patients with non-metastatic T3 tumors, regardless of nodal status, are eligible for adjuvant treatment with KEYTRUDA after nephrectomy<sup>1</sup>'
                                alt=""  
                            />
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>
                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>

                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout >
    );
};

export default Page;
